input, button{
    border:none;
    border-radius: 0.5rem;
}
.big{
    display: block;
    font-size: 2rem;
    padding: 1em;
    margin: 0.5em 1em 1em 1em;
}
label{
    display: block;
    text-align: left;
    margin-left: 2rem;
    font-size: 1.5rem;
}
.wide {
    width: calc(100% - 4em);
}
button[type=submit] {
    color: #6beeff;
    background-color: #356b7a;
    cursor: pointer;
}
button[type=submit]:hover {
    color: #b8f2fa;
    background-color: #56b1cb;
    box-shadow: 0 0 6px 6px #b8f2fa20;
}
button[type=button] {
    color: #317079;
    background-color: #8dc0ce;
    cursor: pointer;
}
button[type=button]:hover {
    color: #317079;
    background-color: #a5dfef;
    box-shadow: 0 0 6px 6px #b8f2fa20;
}
.results{
    position: absolute;
    top:100vh;
    background: #ebfff8;
    color: #333333;
    margin: 0 -5rem;
    padding: 2rem;
    width: calc(100% - 4rem);
    font-size: 2rem;
    min-height: calc(100vh - 4rem);
}
div.result {
    margin: 3rem 0;
}
span.result {
    border: #ff945f 4px solid;
    padding: 1rem;
    margin: 1rem;
    font-weight: bold;
    font-size: 2rem;

}